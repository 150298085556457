// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blockquote-1-js": () => import("./../../../src/pages/blockquote1.js" /* webpackChunkName: "component---src-pages-blockquote-1-js" */),
  "component---src-pages-blockquote-2-js": () => import("./../../../src/pages/blockquote2.js" /* webpackChunkName: "component---src-pages-blockquote-2-js" */),
  "component---src-pages-blockquote-3-js": () => import("./../../../src/pages/blockquote3.js" /* webpackChunkName: "component---src-pages-blockquote-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inset-box-2-js": () => import("./../../../src/pages/insetBox2.js" /* webpackChunkName: "component---src-pages-inset-box-2-js" */),
  "component---src-pages-inset-box-js": () => import("./../../../src/pages/inset-box.js" /* webpackChunkName: "component---src-pages-inset-box-js" */),
  "component---src-pages-labs-blog-component-js": () => import("./../../../src/pages/labsBlogComponent.js" /* webpackChunkName: "component---src-pages-labs-blog-component-js" */),
  "component---src-pages-labs-header-js": () => import("./../../../src/pages/labsHeader.js" /* webpackChunkName: "component---src-pages-labs-header-js" */),
  "component---src-pages-large-quote-js": () => import("./../../../src/pages/large-quote.js" /* webpackChunkName: "component---src-pages-large-quote-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-persona-card-1-js": () => import("./../../../src/pages/personaCard1.js" /* webpackChunkName: "component---src-pages-persona-card-1-js" */),
  "component---src-pages-persona-card-2-js": () => import("./../../../src/pages/personaCard2.js" /* webpackChunkName: "component---src-pages-persona-card-2-js" */),
  "component---src-pages-persona-card-3-js": () => import("./../../../src/pages/personaCard3.js" /* webpackChunkName: "component---src-pages-persona-card-3-js" */),
  "component---src-pages-personas-js": () => import("./../../../src/pages/personas.js" /* webpackChunkName: "component---src-pages-personas-js" */),
  "component---src-pages-pricing-table-1-js": () => import("./../../../src/pages/pricingTable1.js" /* webpackChunkName: "component---src-pages-pricing-table-1-js" */),
  "component---src-pages-pricing-table-2-js": () => import("./../../../src/pages/pricingTable2.js" /* webpackChunkName: "component---src-pages-pricing-table-2-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-shaded-inline-icon-js": () => import("./../../../src/pages/shadedInlineIcon.js" /* webpackChunkName: "component---src-pages-shaded-inline-icon-js" */),
  "component---src-pages-simple-quote-js": () => import("./../../../src/pages/simple-quote.js" /* webpackChunkName: "component---src-pages-simple-quote-js" */),
  "component---src-pages-small-quote-js": () => import("./../../../src/pages/small-quote.js" /* webpackChunkName: "component---src-pages-small-quote-js" */),
  "component---src-pages-summary-box-js": () => import("./../../../src/pages/summary-box.js" /* webpackChunkName: "component---src-pages-summary-box-js" */),
  "component---src-pages-table-js": () => import("./../../../src/pages/table.js" /* webpackChunkName: "component---src-pages-table-js" */),
  "component---src-pages-w-box-js": () => import("./../../../src/pages/w-box.js" /* webpackChunkName: "component---src-pages-w-box-js" */)
}

